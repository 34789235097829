/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { InfiniteStoryBase } from "@quintype/components";
import React from "react";
import PropTypes from "prop-types";
import { StoryPageContent } from "./story";
const InfiniteStories = props => {
  const renderFn = storyProps => {
    const StoryComponent = storyProps.StoryComponent;
    // For client side rendered stories, pass in `StoryComponent`;
    const renderContent = StoryComponent ? props => <StoryComponent {...props} /> : props.renderStoryPageContent;
    return StoryPageContent(storyProps, props.config, renderContent);
  };
  return React.createElement(InfiniteStoryBase, { ...props, render: renderFn, loadItems: props.loadItems });
};
export default InfiniteStories;

InfiniteStories.propTypes = {
  loadItems: PropTypes.func,
  config: PropTypes.object,
  renderStoryPageContent: PropTypes.func
};
