/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import { StoryPageContent } from "./story.js";

class StoryPagePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.storyUpdatedAt = 0;
  }

  componentDidMount() {
    this.collectStoryData();
  }

  collectStoryData() {
    global.addEventListener("message", (event) => {
      if (event.data.story) {
        if (this.state.storyUpdatedAt !== event.data.story["updated-at"]) {
          this.setState({
            story: event.data.story,
            storyUpdatedAt: event.data.story["updated-at"],
            relatedStories: Array(4).fill(event.data.story),
            preview: true,
          });
        }
      }
    });
  }

  render() {
    if (!this.state.story) return <div />;
    return StoryPageContent(
      Object.assign({ index: 0 }, this.state),
      this.props.config,
      this.props.renderStoryPageContent
    );
  }
}

StoryPagePreview.propTypes = {
  config: PropTypes.object,
  renderStoryPageContent: PropTypes.func,
};

export { StoryPagePreview };
