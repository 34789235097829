/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";
import { SKIP_CANONICAL, DATA_LIMIT } from "../isomorphic/constants";

const getTextOfCards = (story) => {
  const storyCards = get(story, ["cards"], []);
  return storyCards
    .reduce((acc, card) => {
      const storyElements = get(card, ["story-elements"], []);
      const cardContent = storyElements.reduce((acc, currentItem) => {
        const elementType = currentItem.subtype || currentItem.type || "";
        const isPromotionalMessage = get(currentItem, ["metadata", "promotional-message"], false);
        if (elementType === "text" && !isPromotionalMessage) {
          return `${acc} ${currentItem.text}`;
        }
        return acc;
      }, "");
      return `${acc} ${cardContent}`;
    }, "")
    .replace(/(<([^>]+)>)/gi, "")
    .replace(/  +/g, " ")
    .trim();
};

const fetchLimitedChars = (data) => (data ? data.substring(0, DATA_LIMIT) : null);

export const getCustomSeoData = (pageBuilderConfig, pageType, data) => {
  let storyCustomSeo = {};
  if (pageType === "story-page") {
    const story = get(data, ["story"], {});
    const { seo = {}, summary = "", subheadline = "" } = story;

    const subHeadline = fetchLimitedChars(subheadline);
    const fullStoryContent = getTextOfCards(story);
    const storyContent = fetchLimitedChars(fullStoryContent);
    storyCustomSeo = {
      description: get(seo, ["meta-description"]) || summary || subHeadline || storyContent,
      ogDescription: summary || storyContent || subHeadline || get(seo, ["meta-description"]),
      twitterDescription: summary || storyContent || subHeadline || get(seo, ["meta-description"]),
    };
    return storyCustomSeo;
  }

  const keyInPb = pageType.split("-")[0];
  if (keyInPb) {
    const disableCanonicalTag = get(pageBuilderConfig, [keyInPb, "disableCanonicalTag"], false);
    const canonicalUrl = disableCanonicalTag ? SKIP_CANONICAL : undefined;
    return {
      canonicalUrl,
      ...storyCustomSeo,
    };
  }
  return storyCustomSeo;
};
