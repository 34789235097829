/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";

import { StoryPage } from "./story.js";

class StoryPublicPagePreview extends React.Component {
  render() {
    return (
      <StoryPage
        data={this.props.data}
        preview={true}
        config={this.props.config}
        renderStoryPageContent={this.props.renderStoryPageContent}
      />
    );
  }
}

StoryPublicPagePreview.propTypes = {
  config: PropTypes.object,
  data: PropTypes.object,
  renderStoryPageContent: PropTypes.func
};
export { StoryPublicPagePreview };
